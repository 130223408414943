import { ABOUT_US } from '../scopes/about-us/routes.constants'
import { CHECKOUT } from '../scopes/checkout/routes-names'
import { CMS } from '../scopes/cms/routes-names'
import { DASHBOARD } from '../scopes/dashboard/routes'
import { HOME } from '../scopes/home/route-names'
import { MODELS_COMPARISON } from '../scopes/models-comparison/routes-names'
import { PRODUCT } from '../scopes/product/route-names'
import { ROUTES as PRODUCT_LIST_ROUTES } from '../scopes/product-list/route-names'
import { ROUTES as REVIEWS } from '../scopes/reviews/route-names'

type TrackingPageType =
  | 'about_us'
  | 'article'
  | 'buyback'
  | 'cms'
  | 'gift-finder'
  | 'home'
  | 'legal-document'
  | 'landing'
  | 'landing_reviews'
  | 'legal-page'
  | 'merchant_reviews'
  | 'models_comparison_category'
  | 'models_comparison_results'
  | 'my_favorites'
  | 'my_profile'
  | 'payment_result'
  | 'product'
  | 'product_reviews'
  | 'product_reviews_legacy'
  | 'reviews'

export const TRACKING_PAGE_TYPE_BY_ROUTE_NAME = {
  [ABOUT_US]: 'about_us',
  [CMS.ABOUT_US]: 'about_us',
  [CHECKOUT.PAYMENT_RESULT]: 'payment_result',
  [CMS.ARTICLE]: 'article',
  [CMS.BUYBACK]: 'buyback',
  [CMS.BRAND_STORE]: 'cms',
  [CMS.BRAND_STORE_CATEGORY]: 'cms',
  [CMS.BUYBACK_CATEGORY]: 'buyback',
  [CMS.EVENT]: 'cms',
  [CMS.GIFT_FINDER_EVENT]: 'gift-finder',
  [CMS.GIFT_FINDER_MAIN]: 'gift-finder',
  [CMS.LEGAL_PREVIOUS_VERSION]: 'legal-document',
  [CMS.LEGAL_PAGE]: 'legal-page',
  [DASHBOARD.KYC.PROFILE]: 'my_profile',
  [DASHBOARD.FAVORITES]: 'my_favorites',
  [HOME]: 'home',
  [PRODUCT_LIST_ROUTES.PRODUCT_LIST]: 'landing',
  [PRODUCT_LIST_ROUTES.SEARCH_RESULTS]: 'landing',
  [PRODUCT_LIST_ROUTES.SELLER_PRODUCT_LIST]: 'landing',
  [PRODUCT.HOME]: 'product',
  [REVIEWS.PAGES.ALL]: 'reviews',
  [REVIEWS.PAGES.LANDING]: 'landing_reviews',
  [REVIEWS.PAGES.MERCHANT]: 'merchant_reviews',
  [REVIEWS.PAGES.PRODUCT]: 'product_reviews',
  [REVIEWS.PAGES.LEGACY_PRODUCT]: 'product_reviews_legacy',
  [MODELS_COMPARISON.CATEGORY]: 'models_comparison_category',
  [MODELS_COMPARISON.COMPARISON_RESULTS]: 'models_comparison_results',
} satisfies Record<string, TrackingPageType>
